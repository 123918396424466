<template>
	<b-container fluid>
		<b-row>
			<Breadcrumb titulo="ATENDIMENTOS POR CLIENTE"
						:items="breadcrumb"
						class="breadcrumb" />
		</b-row>
		<b-row class="mx-0 my-3">
			<b-col class="relatorio-container py-3 px-4">
				<b-row align-h="between">
					<b-col cols="12" md="auto">
						<input type="date" v-model="dataInicio" class="px-2 py-1 mr-3" />
						<input type="date" v-model="dataFim" class="px-2 py-1 mr-3" />
						<div class="input-search px-2 py-1 mr-3">
							<i v-if="selectedCliente" class="fas fa-user text-info"></i>
							<input type="text"
								   ref="input-search"
								   placeholder="Nome, CPF ou Telefone"
								   autocomplete="off"
								   v-model="clientesQuery"
								   :class="`mr-2${selectedCliente ? ' text-info font-weight-bold' : ''}`"
								   :readonly="selectedCliente != null" />
							<b-spinner v-if="searchClientesTimer || clientesRequest" small class="text-info"></b-spinner>
							<i v-else-if="clientes" class="fas fa-times fa-fw" @click="clearClientes"></i>
							<i v-else class="fas fa-search fa-fw" @click="$refs['input-search'].focus()"></i>
							<div class="search-results app-scroll-custom" v-if="clientes && !selectedCliente">
								<span v-if="clientes.length == 0" class="font-italic small px-2">
									Nenhum cliente encontrado.
								</span>
								<div v-for="cliente in clientes" :key="cliente.Id" @click="selectCliente(cliente)">
									<div class="text-nowrap font-weight-bold text-info">
										<i class="fas fa-user mr-1"></i>
										{{cliente.RazaoSocialNome}}
									</div>
									<div class="text-nowrap small">
										<div v-if="cliente.CnpjCpf">
											<b>CPF/CNPJ:</b>
											{{cliente.CnpjCpf}}
										</div>
										<div v-if="cliente.Telefone">
											<b>Telefone:</b>
											{{cliente.Telefone}}
										</div>
										<div>
											<b>Data de Cadastro:</b>
											{{cliente.Inclusao}}
										</div>
									</div>
								</div>
							</div>
						</div>
						<b-button size="sm" variant="info" class="rounded-0 px-4 py-1" @click="searchAtendimentos">Filtrar</b-button>
					</b-col>
					<b-col v-if="!exportacaoAtendimentos && atendimentos && atendimentos.length > 0" cols="12" md="auto">
						<b-button size="sm" variant="info" class="rounded-0 px-4 py-1" @click="exportAtendimentos">
							Exportar
						</b-button>
					</b-col>
				</b-row>
				<b-row>
					<b-col class="relatorio-data-container app-scroll-custom m-3 px-0">
						<b-table v-if="atendimentos || searchAtendimentosIsLoading" ref="atendimentos-selector"
								 striped
								 hover
								 :fields="columns"
								 :items="atendimentos"
								 selectable
								 select-mode="multi"
								 no-select-on-click
								 @row-selected="items => selectedAtendimentos = items.map(item => item.Id)"
								 selected-variant="primary"
								 class="mb-0">
							<template #head(select)="scope">
								<input type="checkbox"
									   :checked="atendimentos?.length == selectedAtendimentos?.length"
									   @change="selectAllAtendimentos($event.target.checked)" />
							</template>
							<template #cell(select)="{ index, rowSelected }">
								<input type="checkbox"
									   :checked="rowSelected"
									   @change="changeAtendimentosSelection(index, $event.target.checked)" />
							</template>
							<template #cell(Informacoes)="scope">
								<i class="fas fa-info-circle fa-lg text-dark" @click.stop="showAtendimentoInfo(scope.item)"></i>
							</template>
							<template #cell(Protocolo)="scope">
								<b :class="selectedAtendimentos?.includes(scope.item.Id) ? 'text-primary' : 'text-dark'">
									{{scope.value}}
								</b>
							</template>
							<template #cell(Status)="scope">
								{{atendimentoStatus[scope.value]}}
							</template>
							<template #cell(DataInicial)="scope">
								{{scope.value.split("-").reverse().join("/")}}
							</template>
						</b-table>
						<div v-if="searchAtendimentosIsLoading" class="text-center font-weight-bold m-2">
							<b-spinner variant="secondary" small class="mr-1"></b-spinner>
							Processando solicitação...
						</div>
						<div v-else-if="atendimentos?.length == 0" class="text-center m-2">
							Não existem atendimentos registrados no sistema com base nos filtros informados.
						</div>
						<div v-else-if="!atendimentos" class="text-secondary m-2">
							<i class="fas fa-address-book mr-2"></i>
							<i>Selecione um cliente e obtenha uma lista com todos os seus atendimentos ao clicar em "Filtrar".</i>
						</div>
					</b-col>
				</b-row>
			</b-col>
			<b-col v-if="exportacaoAtendimentos" class="coluna-lateral-direita py-3 px-0 pr-4" cols="12" md="auto">
				<b-row>
					<b-col class="exportacao-container mx-3 px-3 py-2">
						<b-row>
							<b-col>
								<b>Exportação de atendimentos</b>
								<br />
								<i class="small">Selecione ao lado os atendimentos que deseja exportar</i>
							</b-col>
						</b-row>
						<b-row>
							<b-col class="mt-3">
								<label for="">Observações (opcional):</label>
								<textarea ref="exportacao-observacoes" class="w-100"></textarea>
							</b-col>
						</b-row>
						<b-row>
							<b-col class="action-buttons text-right mt-3 mb-2 pt-3">
								<b-button class="rounded-0"
										  variant="light"
										  @click="exportacaoAtendimentos?.cancelar()">
									Cancelar
								</b-button>
								<b-button class="rounded-0 ml-3"
										  variant="success"
										  :disabled="!selectedAtendimentos || selectedAtendimentos.length == 0"
										  @click="exportacaoAtendimentos?.concluir()">
									Exportar
									{{selectedAtendimentos ? `(${selectedAtendimentos.length})` : ""}}
								</b-button>
							</b-col>
						</b-row>
					</b-col>
				</b-row>
			</b-col>
		</b-row>
		<ModalConversaPadrao ref="modal-informacoes-atendimento"
							 :dadosConversa="selectedAtendimentoDados" />
	</b-container>
</template>
<script>
	import moment from "moment";
	import Breadcrumb from "@/components/breadcrumb/Breadcrumb";
	import axios from "axios";
	import ModalConversaPadrao from '@/components/consultas/ModalCoversaPadrao';
	export default {
		name: "ConsultaAtendimentosCliente",
		components: {
			Breadcrumb,
			ModalConversaPadrao
		},
		data() {
			return {
				breadcrumb: [
					{
						id: "1",
						classe: "fas fa-search",
						texto: "Consultas",
						link: "/consultas",
						cor: "#259cd3"
					},
					{
						id: "2",
						classe: "fa fa-address-book",
						texto: "Atendimentos por Cliente",
						link: "/consulta-atendimentos-cliente",
						cor: "#259cd3"
					}
				],
				fields: [
					{ key: "Informacoes", label: "Informações", thStyle: { width: "120px" } },
					{ key: "Protocolo", label: "Protocolo", sortable: true },
					{ key: "ClienteNome", label: "Cliente", sortable: true },
					{ key: "DataInicial", label: "Data Inicial", sortable: true },
					{ key: "Canal", label: "Canal", sortable: true },
					{ key: "Status", label: "Status", sortable: true },
					{ key: "Atendente", label: "Atendente", sortable: true },
					{ key: "Duracao", label: "Duração", sortable: true }
				],
				dataInicio: moment().format("YYYY-MM-DD"),
				dataFim: moment().format("YYYY-MM-DD"),
				clientes: null,
				clientesQuery: "",
				searchClientesTimer: null,
				clientesRequest: null,
				selectedCliente: null,
				atendimentoStatus: [
					"Ativo",
					"Pendente",
					"Resolvido",
					"Abandonado",
					"Reativo"
				],
				atendimentos: null,
				searchAtendimentosIsLoading: false,
				selectedAtendimentos: null,
				exportacaoAtendimentos: null,
				selectedAtendimento: null
			};
		},
		computed: {
			columns() {
				return this.exportacaoAtendimentos ? [{ key: "select", thStyle: { width: "40px" } }, ...this.fields] : this.fields;
			},
			selectedAtendimentoDados() {
				if (!this.selectedAtendimento) return {};
				this.$refs["modal-informacoes-atendimento"].show();
				return {
					Id: this.selectedAtendimento.Id,
					Protocolo: this.selectedAtendimento.Protocolo,
					ClienteId: this.selectedAtendimento.ClienteId
				};
			}
		},
		watch: {
			clientesQuery() {
				this.searchClientes();
			}
		},
		methods: {
			searchClientes() {
				if (this.searchClientesTimer) clearTimeout(this.searchClientesTimer);
				if (this.clientesRequest) this.clientesRequest.cancel();
				if (this.selectedCliente) return;
				if (!this.clientesQuery.trim()) {
					this.clientes = null;
					this.searchClientesTimer = null;
					this.clientesRequest = null;
					return;
				}
				this.searchClientesTimer = setTimeout(() => {
					this.clientesRequest = axios.CancelToken.source();
					axios.get(`/api/cliente/instant-search?query=${this.clientesQuery}`, {
						cancelToken: this.clientesRequest.token
					}).then(response => {
						this.clientes = response.data;
						this.searchClientesTimer = null;
						this.clientesRequest = null;
					}).catch(() => {
						this.clientes = null;
						this.searchClientesTimer = null;
						this.clientesRequest = null;
					});
				}, 300);
			},
			selectCliente(cliente) {
				this.selectedCliente = cliente;
				this.clientesQuery = cliente.RazaoSocialNome ?? cliente.CnpjCpf ?? cliente.Telefone;
			},
			clearClientes() {
				this.clientesQuery = "";
				this.clientes = null;
				this.selectedCliente = null;
			},
			searchAtendimentos() {
				this.atendimentos = null;
				this.selectedAtendimentos = null;
				this.exportacaoAtendimentos = null;
				const form = {
					DataInicio: this.dataInicio,
					DataFim: this.dataFim,
					ClienteId: this.selectedCliente?.Id
				};
				this.searchAtendimentosIsLoading = true;
				axios.get("/api/relatorios/AtendimentosPorCliente", {
					params: Object.assign({}, ...Object.keys(form).filter(key => form[key]).map(key => ({ [key]: form[key] })))
				}).then(response => {
					this.atendimentos = response.data;
					this.searchAtendimentosIsLoading = false;
				}).catch(() => {
					this.atendimentos = null;
					this.searchAtendimentosIsLoading = false;
				});
			},
			exportAtendimentos() {
				this.selectAllAtendimentos();
				new Promise((resolve, reject) => {
					this.exportacaoAtendimentos = {
						concluir: () => {
							let relatorio = window.open("/print/relatorio-atendimentos-por-cliente", "_blank", `width=${window.innerWidth - 60},height=${window.innerHeight - 60},top=30,left=30,popup=true`);
							relatorio.exportData = {
								atendimentos: this.selectedAtendimentos,
								observacoes: this.$refs["exportacao-observacoes"].value
							};
							resolve();
							this.exportacaoAtendimentos = null;
						},
						cancelar: () => {
							reject();
							this.exportacaoAtendimentos = null;
						}
					};
				}).then(() => {
					this.selectAllAtendimentos(false);
				}).catch(() => {
					this.selectAllAtendimentos(false);
				});
			},
			changeAtendimentosSelection(row, selected) {
				this.$refs["atendimentos-selector"][selected ? "selectRow" : "unselectRow"](row);
			},
			selectAllAtendimentos(select = true) {
				this.$refs["atendimentos-selector"][select ? "selectAllRows" : "clearSelected"]();
			},
			showAtendimentoInfo(atendimento) {
				this.selectedAtendimento = null;
				this.selectedAtendimento = atendimento;
			}
		}
	}
</script>
<style scoped>
	.breadcrumb {
		background-color: #8ddffe;
	}

	.relatorio-container {
		background-color: var(--cinza-3);
		min-width: 690px;
		height: calc(100vh - 170px);
		animation: ease-in slide-left .3s;
	}

	.relatorio-data-container {
		height: calc(100vh - 250px);
		background-color: #fff;
		font-size: 14px;
		color: var(--cinza-5);
		cursor: default;
		animation: ease-in slide-left .5s;
	}

		.relatorio-data-container table {
			color: var(--cinza-5);
			animation: ease-in slide-up .3s;
		}

	input, select, .input-search {
		display: inline-block;
		background-color: #fff;
		border: 0;
		outline-style: none;
		font-size: 14px;
		color: var(--cinza-5);
	}

	.input-search {
		position: relative;
	}

		.input-search > input[type=text] {
			width: 200px;
			padding: 1px;
		}

			.input-search > input[type=text]:focus {
				box-shadow: none;
			}

		.input-search > i.fa-user {
			font-size: 13px;
			margin-right: 5px;
		}

			.input-search > i.fa-user + input[type=text] {
				width: 184px;
			}

		.input-search > i:not(.fa-circle-notch) {
			cursor: pointer;
		}

	.search-results {
		position: absolute;
		min-width: 100%;
		max-height: 50vh;
		top: calc(100% + 3px);
		left: -1px;
		background-color: #fff;
		z-index: 1000;
		box-shadow: 2px 2px 2px #ccc;
		border: 1px var(--cinza-3) solid;
		overflow-x: hidden;
		animation: ease-in slide-down .3s;
	}

		.search-results > div {
			padding: 5px 10px;
			border-bottom: 1px var(--cinza-3) solid;
			cursor: pointer;
			transition: all ease-in-out .3s;
		}

			.search-results > div:hover {
				background-color: var(--cinza-1);
			}

	.fas.fa-info-circle {
		cursor: pointer;
	}

	.coluna-lateral-direita {
		background-color: var(--cinza-3);
		height: calc(100vh - 170px);
	}

	.exportacao-container {
		background-color: #fff;
		width: 25vw;
	}

		.exportacao-container label {
			display: block;
			color: var(--cinza-5);
			font-weight: bold;
			font-size: 12px;
			margin-bottom: 0;
		}

		.exportacao-container textarea {
			border: 1px var(--cinza-4) solid;
			background-color: var(--cinza-2);
			font-size: 14px;
			padding: 5px 7px;
			color: var(--cinza-5);
			outline-style: none;
			height: 120px;
			transition: all ease-in-out .3s;
		}

			.exportacao-container textarea:focus {
				filter: brightness(1.03);
			}

		.exportacao-container .action-buttons {
			border-top: 2px var(--cinza-3) solid;
		}

	@keyframes slide-left {
		0% {
			opacity: 0;
			left: 30px;
		}

		70% {
			opacity: .7;
			left: 7px;
		}

		100% {
			opacity: 1;
			left: 0;
		}
	}

	@keyframes slide-down {
		0% {
			opacity: 0;
			margin-top: -20px;
		}

		70% {
			opacity: .7;
			margin-top: -7px;
		}

		100% {
			opacity: 1;
			margin-top: 0;
		}
	}

	@keyframes slide-up {
		0% {
			opacity: 0;
			margin-top: 20px;
		}

		70% {
			opacity: .7;
			margin-top: 7px;
		}

		100% {
			opacity: 1;
			margin-top: 0;
		}
	}
</style>